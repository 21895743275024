import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import './iview/styles/iview.css'
import './iview/mytheme/index.less'
import dataV from '@jiaminghi/data-view'
import '@/assets/myfont/iconfont.css'
import lazyload from 'vue-lazyload'
//  import Base64 from 'js-base64'
// import locale from 'view-design/dist/locale/en-US'
import i18n from '@/locale'

// import wsk from '@/api/websocket' // 引入 websocket
// Vue.prototype.wsk = wsk // 挂载

//  Vue.use(Base64)
Vue.use(lazyload);

Vue.config.productionTip = false
Vue.use(ViewUI)

// Vue.use(ViewUI, { locale })
// Vue.use(ViewUI, {
// i18n:(key, value) => i18n.t(key, value)
// })
Vue.use(dataV)
Vue.use(i18n)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
