// @vue/component
import axios from '@/libs/api.request'
import IdentifyCode from './IdentifyCode/IdentifyCode.vue';
import aes from '@/api/crypto'

export default {
    name: 'LoginView',
    components: {
        IdentifyCode
    },
    mixins: [],
    props: {},
    data () {
        return {
            logining: false,
            loginForm: {
                userName: '',
                inputCheckCode: '',
                password: ''
            },
            ruleValidate: {
                userName: [
                    { required: true, message: '用户名不能为空!', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '密码不能为空!', trigger: 'blur' }
                ]
            },
            checkCode: '',
            //  /WebServer/GetCaptcha?random=1234
            ideantyfyUrl: ''
        }
    },

    computed: {},

    watch: {},

    created () {},

    mounted () {
        this.GetIdentyfy()
    },

    methods: {
        GetIdentyfy () {
            axios.request({
                url: 'getCaptchaAxios',
                method: 'get'
            }).then(res => {
                // console.log('登录返回', res)
                if (res.data.code === 200) {
                    this.ideantyfyUrl = res.data.data.base64;
                    this.checkCode = res.data.data.identy
                } else {
                    // console.log('获取验证码异常');
                }
            }).catch(() => {
                // console.log('获取验证码异常' + err);
            });
        },
        HandleSubmit (name) {
            /*
            const inCode = this.inputCheckCode.toUpperCase()
            if (inCode === this.checkCode) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        const Base64 = require('js-base64').Base64
                        const data = {
                            userName: this.loginForm.userName,
                            password: Base64.encode(this.loginForm.password)
                        }
                        console.log('登录信息', data)
                        this.logining = true;
                        //  axios.requestCrossDomain({
                        axios.request({
                            url: 'UserLogin',
                            data,
                            headers: {
                                'content-type': 'application/json'
                            },
                            method: 'post'
                        }).then(res => {
                            console.log('登录返回', res)
                            if (res.data.code === 200) {
                                const token = res.data.data;
                                this.$store.commit('setToken', token);
                                this.$router.replace({ path: '/FirstPage' });
                            } else {
                                this.$Message.error('登录失败: ' + res.data.message);
                            }
                            this.logining = false;
                        }).catch(err => {
                            this.logining = false;
                            window.console.log('登录异常' + err);
                        });
                    } else {
                        this.$Message.error('表单错误!');
                    }
                })
            } else {
                this.$Message.error('验证码错误!');
            }
            // 通知验证码模块刷新验证码
            this.$refs.ref_validateCode.draw()
            */
            //  后台验证
            this.$refs[name].validate((valid) => {
                if (valid) {
                    //  const Base64 = require('js-base64').Base64
                    const data = {
                        userName: this.loginForm.userName,
                        //  password: Base64.encode(this.loginForm.password),
                        password: aes.encrypt(this.loginForm.password),
                        inputCheckCode: this.loginForm.inputCheckCode
                    }
                    // console.log('登录信息', data)
                    this.logining = true;
                    //  axios.requestCrossDomain({
                    axios.request({
                        url: 'UserLogin',
                        data,
                        headers: {
                            'content-type': 'application/json',
                            identy: this.checkCode
                        },
                        method: 'post'
                    }).then(res => {
                        // console.log('登录返回', res)
                        if (res.data.code === 200) {
                            const token = res.data.data;
                            this.$store.commit('setToken', token);
                            this.$router.replace({ path: '/FirstPage' });
                        } else {
                            this.$Message.error('登录失败: ' + res.data.message);
                            this.ChangeIdentyfyurl()
                        }
                        this.logining = false;
                    }).catch(err => {
                        this.logining = false;
                        window.console.log('登录异常' + err);
                    });
                } else {
                    this.$Message.error('表单错误!');
                }
            })
            //  this.ChangeIdentyfyurl()
        },
        ChangeIdentyfyurl () {
            this.GetIdentyfy()
        }
    }
}
