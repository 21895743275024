export default {
    state: {
        EnvironmentProduceFormData: {
            o2Vol: '-Vol',
            ch4Lel: '-PPM',
            h2sPpm: '-PPM',
            coPpm: '-PPM',
            co2Ppm: '-PPM',
            cl2Ppm: '-PPM',
            h2Ppm: '-PPM',
            temp: '-℃',
            hum: '-%RH',
            timeStamp: '',
            treeId: ''
        },
        AlarmInfo: {
            alarmData: {
                treeId: '',
                upTime: '',
                alarmTypeId: '',
                levelId: '',
                alarmDesc: '',
                alarmData: '',
                handlerMark: '',
                callMark: ''
            },
            unHandledNum: 0
        }
    },
    getters: {
        getEnvironmentProduceFormData: (state) => (EnvironmentProduceID) => {
            if (state.EnvironmentProduceFormData.treeId === EnvironmentProduceID) {
                return state.EnvironmentProduceFormData
            } else {
                const EnvironmentProduceFormDataDefault = {
                    o2Vol: '-Vol',
                    ch4Lel: '-PPM',
                    h2sPpm: '-PPM',
                    coPpm: '-PPM',
                    co2Ppm: '-PPM',
                    cl2Ppm: '-PPM',
                    h2Ppm: '-PPM',
                    temp: '-℃',
                    hum: '-%RH',
                    timeStamp: '',
                    treeId: ''
                }
                return EnvironmentProduceFormDataDefault
            }
        },
        getAlarmInfo: (state) => (AlarmInfoTreeID) => {
            //  console.log('获取缓存的报警数据-treeId', state.AlarmInfo.alarmData == null ? '没有缓存报警数据' : state.AlarmInfo.alarmData.treeId)
            //  console.log('获取缓存的报警数据-AlarmInfoTreeID', AlarmInfoTreeID)
            if (state.AlarmInfo.alarmData != null && state.AlarmInfo.alarmData.treeId === AlarmInfoTreeID) {
                return state.AlarmInfo
            } else {
                const AlarmInfoBuf = {
                    alarmData: {
                        treeId: '',
                        upTime: '',
                        alarmTypeId: '',
                        levelId: '',
                        alarmDesc: '',
                        alarmData: '',
                        handlerMark: '',
                        callMark: ''
                    },
                    unHandledNum: 0
                }
                return AlarmInfoBuf
            }
        }
    },
    mutations: {
        /**
        * @author 张现利
        * @time 2022.04.24
        * @func 记录环境监测设备的最新一条的环境监测数据，因为地图的mark滑过可能会频发触发，此操作为了阻止频繁向后台发送获取数据的请求
        */
        setEnvironmentProduceFormData (state, EnvironmentProduceFormData) {
            state.EnvironmentProduceFormData = EnvironmentProduceFormData;
        },
        /**
        * @author 张现利
        * @time 2022.04.24
        * @func 记录所有设备的最新一条报警数据，因为地图的mark滑过可能会频发触发，此操作为了阻止频繁向后台发送获取数据的请求
        */
         setAlarmInfo (state, AlarmInfo) {
            state.AlarmInfo = AlarmInfo;
        }
    },
    actions: {},
    modules: {
    }
}
