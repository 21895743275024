import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCnLocale from 'view-design/dist/locale/zh-CN'
import enUsLocale from 'view-design/dist/locale/en-US'
import ViewUI from 'view-design'
import zh from './lang/zh-CN'
import en from './lang/en-US'

Vue.use(ViewUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(VueI18n)

const messages = {
  zh: Object.assign(zhCnLocale, zh),
  en: Object.assign(enUsLocale, en)
}
const language = (navigator.language || 'zh').toLocaleLowerCase() // 这是获取浏览器的语言

const i18n = new VueI18n({
  legacy: false,
  globalInjection: true,
  silentTranslationWarn: true,
  locale: localStorage.getItem('lang') || language.split('-')[0] || 'zh', // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: 'zh', // 设置备用语言
  messages
})
export default i18n
