import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView/LoginView.vue'
import store from '@/store'
import iView from 'view-design'
import { setToken, getToken, setTitle } from '@/libs/util'
import config from '@/config'
const { homeName } = config

Vue.use(VueRouter)

const LOGIN_PAGE_NAME = 'login'
const ERROR_PAGE_NAME = 'errorpage_401'

const routes = [
    {
        path: '/',
        redirect: {
            name: 'login'
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
              hideInMenu: true,
              title: '登录',
              notCache: true,
              icon: ''
        },
        component: LoginView
    },
    {
        path: '/errorpage_401',
        name: 'errorpage_401',
        meta: {
              hideInMenu: true,
              title: '错误',
              notCache: true,
              icon: ''
        },
        component: () => import('../views/ErrorPage401/ErrorPage401.vue')
    },
    {
        path: '/FirstPage',
        name: 'FirstPage',
        meta: {
              hideInMenu: true,
              title: '首页',
              notCache: true,
              icon: 'md-home'
        },
        component: () => import('../views/FirstPage/FirstPage.vue'),
        children: [
            {
                path: '/UsersManager',
                name: 'UsersManager',
                meta: {
                      hideInMenu: true,
                      title: '用户管理',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/UserCtrlView/UserCtrlView.vue')
            },
            {
                path: '/Maintenance',
                name: 'Maintenance',
                meta: {
                      hideInMenu: true,
                      title: '系统配置',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/MaintenanceView/MaintenanceView.vue')
            },
            {
                path: '/IocPage',
                name: 'IocPage',
                meta: {
                      hideInMenu: true,
                      title: '首页总览',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/IocView/IocView.vue')
            },
            {
                path: '/IocPageNew',
                name: 'IocPageNew',
                meta: {
                      hideInMenu: true,
                      title: '首页总览',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/IocView/IocViewNew.vue')
            },
            {
                path: '/CompanyCtrl',
                name: 'CompanyCtrl',
                meta: {
                      hideInMenu: true,
                      title: '公司维护',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/CompanyCtrlView/CompanyCtrlView.vue')
            },
            {
                path: '/CompanyRelation',
                name: 'CompanyRelation',
                meta: {
                      hideInMenu: true,
                      title: '公司关系',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/CompanyRelationView/CompanyRelationView.vue')
            },
            {
                path: '/ElectricPart',
                name: 'ElectricPart',
                meta: {
                      hideInMenu: true,
                      title: '电力专业',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/EleProView/EleProView.vue')
            },
            {
                path: '/AlarmLevelCtrl',
                name: 'AlarmLevelCtrl',
                meta: {
                      hideInMenu: true,
                      title: '报警等级',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/AlarmLevelCtrlView/AlarmLevelCtrlView.vue')
            },
            {
                path: '/RobotEquipmentModel',
                name: 'RobotEquipmentModel',
                meta: {
                      hideInMenu: true,
                      title: '机器人设备型号',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/RobotEquipmentModelView/RobotEquipmentModelView.vue')
            },
            {
                path: '/AlarmTypeCtrl',
                name: 'AlarmTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '报警类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/AlarmTypeCtrlView/AlarmTypeCtrlView.vue')
            },
            {
                path: '/ImageRecAlarmTypeCtrl',
                name: 'ImageRecAlarmTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '图像识别报警类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/ImageRecAlarmTypeCtrlView/ImageRecAlarmTypeCtrlView.vue')
            },
            {
                path: '/ThresholdTypeCtrl',
                name: 'ThresholdTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '阈值类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/ThresholdTypeCtrlView/ThresholdTypeCtrlView.vue')
            },
            {
                path: '/HisTableTypeCtrl',
                name: 'HisTableTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '数据表管理',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/HisTableTypeView/HisTableTypeView.vue')
            },
            {
                path: '/MonitorTypeCtrl',
                name: 'MonitorTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '监测类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/MonitorTypeCtrlView/MonitorTypeCtrlView.vue')
            },
            {
                path: '/GroupManager',
                name: 'GroupManager',
                meta: {
                      hideInMenu: true,
                      title: '组织管理',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/GroupCtrlView/GroupCtrlView.vue')
            },
            {
                path: '/NetTypeCtrl',
                name: 'NetTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '通讯类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/NetTypeCtrlView/NetTypeCtrlView.vue')
            },
            {
                path: '/NodeTypeCtrl',
                name: 'NodeTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '节点类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/NodeTypeCtrlView/NodeTypeCtrlView.vue')
            },
            {
                path: '/PowerModelCtrl',
                name: 'PowerModelCtrl',
                meta: {
                      hideInMenu: true,
                      title: '供电类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/PowerModelCtrlView/PowerModelCtrlView.vue')
            },
            {
                path: '/ProduceTypeCtrl',
                name: 'ProduceTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '产品类型',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/ProduceTypeCtrlView/ProduceTypeCtrlView.vue')
            },
            {
                path: '/DeviceManager',
                name: 'DeviceManager',
                meta: {
                      hideInMenu: true,
                      title: '设备树',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/NodeTreeCtrlView/NodeTreeCtrlView.vue')
            },
            {
                path: '/MqttServerTopicView',
                name: 'MqttServerTopicView',
                meta: {
                      hideInMenu: true,
                      title: '物联网服务',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/MqttServerTopicView/MqttServerTopicView.vue')
            },
            {
                path: '/DailyInspection',
                name: 'DailyInspection',
                meta: {
                      hideInMenu: true,
                      title: '日常巡检',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/InspectionView/InspectionView.vue')
            },
            {
                path: '/FanWaterPumpInfo',
                name: 'FanWaterPumpInfo',
                meta: {
                hideInMenu: true,
                title: '风机水泵信息',
                notCache: true,
                icon: ''
                },
                component: () => import('../views/FanWaterPumpView/FanWaterPumpInfo.vue')
            },
            {
                path: '/DlsdGXCWData',
                name: 'DlsdGXCWData',
                meta: {
                      hideInMenu: true,
                      title: '光纤测温',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/GxcwDataView/GxcwDataView.vue')
            },
            {
                path: '/DlsdJFData',
                name: 'DlsdJFData',
                meta: {
                      hideInMenu: true,
                      title: '电缆局放',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/JFDataView/JFDataView.vue')
            },
            {
                path: '/DlsdPhoneData',
                name: 'DlsdPhoneData',
                meta: {
                      hideInMenu: true,
                      title: '应急通讯',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/PhoneDataView/PhoneDataView.vue')
            },
            // {
            //     path: '/DlsdFireData',
            //     name: 'DlsdFireData',
            //     meta: {
            //           hideInMenu: true,
            //           title: '隧道消防',
            //           notCache: true,
            //           icon: ''
            //     },
            //     component: () => import('../views/DataView/Tunnel/FireDataView/FireDataView.vue')
            // },
            {
                path: '/DlsdHJData',
                name: 'DlsdHJData',
                meta: {
                      hideInMenu: true,
                      title: '环境监测',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/HJDataView/HJDataView.vue')
            },
            {
                path: '/DlsdWaterData',
                name: 'DlsdWaterData',
                meta: {
                      hideInMenu: true,
                      title: '水位监测',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/WaterDataView/WaterDataView.vue')
            },
            {
                path: '/DlsdHchlData',
                name: 'DlsdHchlData',
                meta: {
                      hideInMenu: true,
                      title: '护层电流',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/HchlDataView/HchlDataView.vue')
            },
            {
                path: '/PumpFanStatus',
                name: 'PumpFanStatus',
                meta: {
                    hideInMenu: true,
                    title: '风机水泵',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/PumpFanStatusView/PumpFanStatus.vue')
            },
            {
                path: '/CtrlInstruction',
                name: 'CtrlInstruction',
                meta: {
                    hideInMenu: true,
                    title: '指令类型信息',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/CtrlInstructionView/CtrlInstruction.vue')
            },
            {
                path: '/HiddenDangerType',
                name: 'HiddenDangerType',
                meta: {
                    hideInMenu: true,
                    title: '隐患类型',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/HiddenDangerView/HiddenDangerType/HiddenDangerType.vue')
            },
            // {
            //     path: '/HiddenDangerTypeWindow',
            //     name: 'HiddenDangerTypeWindow',
            //     meta: {
            //         hideInMenu: true,
            //         title: '隐患类型',
            //         notCache: true,
            //         icon: ''
            //     },
            //     component: () => import('../views/HiddenDangerView/HiddenDangerType/HiddenDangerTypeWindow/HiddenDangerTypeWindow.vue')
            // },
            {
                path: '/HiddenDangerSource',
                name: 'HiddenDangerSource',
                meta: {
                    hideInMenu: true,
                    title: '隐患来源',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/HiddenDangerView/HiddenDangerSource/HiddenDangerSource.vue')
            },
            // {
            //     path: '/SourceWin',
            //     name: 'SourceWin',
            //     meta: {
            //         hideInMenu: true,
            //         title: '隐患来源',
            //         notCache: true,
            //         icon: ''
            //     },
            //     component: () => import('../views/HiddenDangerView/HiddenDangerSource/SourceWin/SourceWin.vue')
            // },
            {
                path: '/HiddenDangerLevel',
                name: 'HiddenDangerLevel',
                meta: {
                    hideInMenu: true,
                    title: '隐患等级',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/HiddenDangerView/HiddenDangerLevel/HiddenDangerLevel.vue')
            },
            // {
            //     path: '/HiddenDangerLevelWindow',
            //     name: 'HiddenDangerLevelWindow',
            //     meta: {
            //         hideInMenu: true,
            //         title: '隐患等级',
            //         notCache: true,
            //         icon: ''
            //     },
            //     component: () => import('../views/HiddenDangerView/HiddenDangerLevel/HiddenDangerLevelWindow/HiddenDangerLevelWindow.vue')
            // },
            {
                path: '/HiddenDanger',
                name: 'HiddenDanger',
                meta: {
                    hideInMenu: true,
                    title: '隐患管理',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/HiddenDangerView/HiddenDanger.vue')
            },
            // {
            //     path: '/HiddenDangerWindow',
            //     name: 'HiddenDangerWindow',
            //     meta: {
            //         hideInMenu: true,
            //         title: '隐患记录',
            //         notCache: true,
            //         icon: ''
            //     },
            //     component: () => import('../views/HiddenDangerView/HiddenDangerWindow/HiddenDangerWindow.vue')
            // },
            {
                path: '/DlsdManholeData',
                name: 'DlsdManholeData',
                meta: {
                      hideInMenu: true,
                      title: '井盖监测',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Tunnel/ManholeDataView/ManholeDataView.vue')
            },
            {
                path: '/JDWDataCtrlView',
                name: 'JDWDataCtrlView',
                meta: {
                      hideInMenu: true,
                      title: '变电接地网',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/JDWView/JDWDataCtrlView.vue')
            },
            {
                path: '/OverflowDataView',
                name: 'OverflowDataView',
                meta: {
                      hideInMenu: true,
                      title: '溢水',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/OverflowDataView/OverflowDataView.vue')
            },
            {
                path: '/CameraVideo',
                name: 'CameraVideo',
                meta: {
                      hideInMenu: true,
                      title: '实时视频',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Visualization/VideoView/VideoView.vue')
            },
            // {
            //     path: '/TunnelRobotVideo',
            //     name: 'TunnelRobotVideo',
            //     meta: {
            //           hideInMenu: true,
            //           title: '隧道机器人',
            //           notCache: true,
            //           icon: ''
            //     },
            //     component: () => import('../views/DataView/Visualization/TunnelRobotView/TunnelRobotView.vue')
            // },
            {
                path: '/XXRobotVideo',
                name: 'XXRobotVideo',
                meta: {
                      hideInMenu: true,
                      title: '巡线机器人',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Visualization/XXRobotView/XXRobotView.vue')
            },
            {
                path: '/LtafXTB',
                name: 'LtafXTB',
                meta: {
                      hideInMenu: true,
                      title: '立体安防激光',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Visualization/LtafXTBView/LtafXTBView.vue')
            },
            {
                path: 'LtafPointCloud',
                name: 'LtafPointCloud',
                meta: {
                      hideInMenu: true,
                      title: '立体安防点云',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/Visualization/LtafCloudPicView/LtafCloudPicView.vue')
            },
            {
                path: '/McEventTypeCtrl',
                name: 'McEventTypeCtrl',
                meta: {
                      hideInMenu: true,
                      title: '井盖事件',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/McEventTypeCtrlView/McEventTypeCtrlView.vue')
            },
            {
                path: '/AlarmDataCtrlView',
                name: 'AlarmDataCtrlView',
                meta: {
                    hideInMenu: true,
                    title: '报警统计',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/AlarmDataCtrlView/AlarmDataCtrlView.vue')
            },
            {
                path: '/DeviceStastusView',
                name: 'DeviceStastusView',
                meta: {
                    hideInMenu: true,
                    title: '设备状态',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/DeviceStastusView/DeviceStastusView.vue')
            },
            {
                path: '/ShoutVoiceCtrl',
                name: 'ShoutVoiceCtrl',
                meta: {
                    hideInMenu: true,
                    title: '告警音频类型',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/ShoutVoiceCtrlView/ShoutVoiceCtrlView.vue')
            },
            {
                path: '/RobotTargetCtrl',
                name: 'RobotTargetCtrl',
                meta: {
                    hideInMenu: true,
                    title: '巡检目标',
                    notCache: true,
                    icon: ''
                },
                component: () => import('../views/RobotTargetCtrlView/RobotTargetCtrlView.vue')
            },
            {
                path: '/TowerTiltData',
                name: 'TowerTiltData',
                meta: {
                      hideInMenu: true,
                      title: '杆塔倾斜',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/TowerTiltData/TowerTiltData.vue')
            },
            {
                path: '/MeteorologicalData',
                name: 'MeteorologicalData',
                meta: {
                      hideInMenu: true,
                      title: '输电气象',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/MeteorologicalData/MeteorologicalData.vue')
            },
            {
                path: '/LineTempData',
                name: 'LineTempData',
                meta: {
                      hideInMenu: true,
                      title: '导线测温',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/LineTempData/LineTempData.vue')
            },
            {
                path: '/TowerStressData',
                name: 'TowerStressData',
                meta: {
                      hideInMenu: true,
                      title: '杆塔应力',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/TowerStressData/TowerStressData.vue')
            },
            {
                path: '/BoltTightenData',
                name: 'BoltTightenData',
                meta: {
                      hideInMenu: true,
                      title: '螺栓拧紧力',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/BoltTightenData/BoltTightenData.vue')
            },
            {
                path: '/LineGallopData',
                name: 'LineGallopData',
                meta: {
                      hideInMenu: true,
                      title: '导线舞动',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/LineGallopData/LineGallopData.vue')
            },
            {
                path: '/LineIcingData',
                name: 'LineIcingData',
                meta: {
                      hideInMenu: true,
                      title: '覆冰张力',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/LineIcingData/LineIcingData.vue')
            },
            {
                path: '/GroundElectrodeData',
                name: 'GroundElectrodeData',
                meta: {
                      hideInMenu: true,
                      title: '接地极监测',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/GroundElectrodeData/GroundElectrodeData.vue')
            },
            {
                path: '/RadarDance',
                name: 'RadarDance',
                meta: {
                      hideInMenu: true,
                      title: '导线位移',
                      notCache: true,
                      icon: ''
                },
                component: () => import('../views/DataView/transmission/RadarDance/RadarDance.vue')
            }
        ]
    }
    // {
    //      path: '/SdIOC',
    //      name: 'SdIOC',
    //      meta: {
    //            hideInMenu: true,
    //            title: '输电数据大屏',
    //            notCache: true,
    //            icon: ''
    //      },
    //      component: () => import('../views/SdView/SdView.vue')
    // }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    iView.LoadingBar.start()
    const token = getToken()
    //  console.log('token', token)
    if (!token && to.name !== LOGIN_PAGE_NAME) {
        //  未登录且要跳转的页面不是登录页
        next({
            name: LOGIN_PAGE_NAME // 跳转到登录页
        })
    } else if (!token && to.name === LOGIN_PAGE_NAME) {
        //  未登陆且要跳转的页面是登录页
        next() // 跳转
    } else if (token && to.name === LOGIN_PAGE_NAME) {
        //  已登录且要跳转的页面是登录页
        next({
            name: homeName //   跳转到homeName页
        })
    } else if (to.name === ERROR_PAGE_NAME) {
        next()
    } else {
        if (store.state.user.hasGetInfo) {
            next()
        } else {
            //  window.console.log('还没有用户信息，获取用户信息!')
            store.dispatch('getUserInfo').then(baseInfo => {
                //  存储用户信息
                next()
            }).catch(err => {
                window.console.log('router跳转获取当前用户信息---进入异常', err)
                setToken('')
                next({
                    name: 'login'
                })
            })
        }
    }

    if (to.meta.title) {
        document.title = to.meta.title
    }
})

router.afterEach(to => {
    setTitle(to, router.app)
    iView.LoadingBar.finish()
    window.scrollTo(0, 0)
})

export default router
