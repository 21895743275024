import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/User'
import HandlerGisExFormData from './module/HandlerGisExFormData'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {
    },
    mutations: {},
    actions: {
    },
    modules: {
        user,
        HandlerGisExFormData
    }
})
